import React, { useState, useEffect, useCallback } from "react";
import { graphql } from "gatsby";
import Image from "gatsby-plugin-sanity-image";

import { motion, AnimatePresence } from "framer-motion";
import { StandardModal } from "@ui/standardModal";
import { useScrollFreeze } from "@hooks/useScrollFreeze";
import Moment from "react-moment";
import moment from "moment";
import cx from "classnames";
import useEmblaCarousel from "embla-carousel-react";

import { PortableTextBlock } from "@modules/portableTextBlock";
import AddToCalendarHOC from "react-add-to-calendar-hoc";
import { Helmet } from "react-helmet";
import Layout from "@components/layout";
import { VideoModule } from "../components/modules/videoModule";

import Prev from "@svg/prev.svg";
import Next from "@svg/next.svg";
import Cross from "@svg/cross.svg";

const Details = ({
  sessions,
  eventColor,
  content,
  ticketsLink,
  heading,
  excerpt,
  imageGallery,
  onOpen,
}) => {
  // const { sessions, eventColor, content, ticketsLink } = project || {};

  const ATCDropdown = (args) => (
    <ul className="atc-dropdown">
      {args.children.map((link, i) => (
        <li
          className="text-sm leading-sm md:text-lg md:leading-lg mt-2 underline"
          key={i}
        >
          {link}
        </li>
      ))}
    </ul>
  );
  const ATCWrapper = (args) => (
    <a
      onClick={args.onClick}
      className="atc-item underline text-sm leading-sm md:text-xl md:leading-xl font-bold mt-1 block"
      href="/"
    >
      {args.children}
    </a>
  );

  const AddToCalendarDropdown = AddToCalendarHOC(ATCWrapper, ATCDropdown);

  return (
    <>
      {sessions?.length > 0 && (
        <div className="mt-16 lg:mt-48 grid grid-cols-14 gap-y-10">
          <div className="col-end-14 col-start-2 lg:col-end-9">
            <span
              className="text-sm leading-sm md:text-2xl md:leading-2xl font-helix-medium"
              style={{ color: eventColor?.picker?.value }}
            >
              Date
            </span>

            {sessions?.map((session, i) => {
              const startDatetime = new Date(session?.date);
              const endDatetime = new Date(sessions?.date);
              const duration =
                startDatetime.getHours() - endDatetime.getHours();
              const event = {
                title: heading,
                description: excerpt,
                location: sessions[0]?.venue?.title,
                duration,
                endDatetime: moment(endDatetime).format("YYYYMMDDTHHmmssZ"),
                startDatetime: moment(startDatetime).format("YYYYMMDDTHHmmssZ"),
              };
              return (
                <>
                  <p
                    key={i}
                    className={`text-xl leading-xl md:text-4xl md:leading-4xl font-helix-medium ${
                      i === 0 ? "mt-0" : "mt-4 lg:mt-8"
                    }`}
                  >
                    <Moment format="DD MMMM YYYY, h:mmA">
                      {session?.date}
                    </Moment>
                  </p>
                  {new Date(session?.date) > new Date() && (
                    <AddToCalendarDropdown
                      linkProps={{
                        className: "atc-dropdown-title",
                      }}
                      event={event}
                    />
                  )}
                </>
              );
            })}

            {sessions?.length === 0 && (
              <span className="text-xl leading-xl md:text-4xl md:leading-4xl font-helix-medium block">
                Event Finished
              </span>
            )}
          </div>

          <div className="col-start-2 col-end-14 lg:col-start-10 lg:col-end-13 2xl:col-end-14">
            <span
              className="text-sm leading-sm md:text-2xl md:leading-2xl font-helix-medium"
              style={{ color: eventColor?.picker?.value }}
            >
              Venue
            </span>
            <p className="text-xl leading-xl md:text-4xl md:leading-4xl font-helix-medium">
              {sessions[0]?.venue?.title}
            </p>
            <a
              href={sessions[0]?.venue?.venueLink}
              target="_blank"
              rel="noreferrer"
              className="underline text-sm leading-sm md:text-xl md:leading-xl font-bold lg:mt-2 block"
            >
              View map
            </a>
          </div>
        </div>
      )}
      <div className="grid grid-cols-14 mt-16 lg:mt-40 mb-24 lg:mb-40">
        <div className="col-start-2 col-end-14 lg:col-end-11 event-info">
          <span
            style={{ color: eventColor?.picker?.value }}
            className="text-sm leading-sm md:text-2xl md:leading-2xl font-helix-medium"
          >
            Information
          </span>
          <div className="prose md:prose-lg">
            <PortableTextBlock text={content} />
          </div>
          {ticketsLink && (
            <div className="flex mt-10 ">
              {new Date(sessions[sessions?.length - 1]?.date) > new Date() && (
                <a
                  className="btn-navy block mr-2"
                  href={ticketsLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  Book Tickets
                </a>
              )}
              {imageGallery?.length > 0 && (
                <button className="btn-navy mr-2 " onClick={() => onOpen()}>
                  View Gallery
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const Intro = ({
  heading,
  image,
  caption,
  eventColor,
  ticketsLink,
  excerpt,
  sessions,
  featuredVideo,
  imageGallery,
  onOpen,
  meta,
}) => {
  return (
    <div className="grid grid-cols-14 mt-8 xl:mt-16 w-full">
      <div className="mt-8 lg:mt-0 row-start-3 row-end-4 lg:row-start-1 lg:row-end-2 col-start-2 col-end-14 lg:col-end-7 flex flex-col justify-center">
        <h1
          style={{ color: eventColor?.picker?.value }}
          className="text-4xl leading-4xl md:text-5xl md:leading-5xl font-helix-bold"
        >
          {heading}
        </h1>
        <p className="text-sm leading-sm md:text-lg md:leading-lg font-helix-medium">
          {excerpt}
        </p>
        {ticketsLink && (
          <div className="flex mt-4 lg:mt-8">
            {new Date(sessions[sessions?.length - 1]?.date) > new Date() && (
              <a
                href={ticketsLink}
                target="_blank"
                rel="noreferrer"
                className="btn-navy mr-1 "
              >
                Book Tickets
              </a>
            )}

            {imageGallery?.length > 0 && (
              <button className="btn-navy " onClick={() => onOpen()}>
                View Gallery
              </button>
            )}
          </div>
        )}
      </div>

      <div
        className={cx(
          "row-start-1 row-end-2 col-start-1 lg:col-start-8 col-end-15",
          {
            "aspect-w-2 aspect-h-2": !featuredVideo,
          }
        )}
      >
        {featuredVideo ? (
          <VideoModule video={featuredVideo} />
        ) : (
          <Image {...meta?.metaImage} className="w-full object-cover" />
        )}
      </div>

      <div className="row-start-2 row-end-3 col-start-2 lg:col-start-8 col-end-14">
        {caption && (
          <p className="text-xs leading-xs md:text-sm md:leading-sm mt-4">
            {caption}
          </p>
        )}
      </div>
    </div>
  );
};

const PrevButton = ({ enabled, onClick }) => (
  <button
    className={cx(
      "col-start-2 col-end-3 transition-all ease-in-out duration-300    cursor-pointer touch-manipulation self-center ",
      {
        "opacity-20": !enabled,
      }
    )}
    onClick={onClick}
    disabled={!enabled}
  >
    <Prev className=" m-auto fill-white " />
  </button>
);

const NextButton = ({ enabled, onClick }) => (
  <button
    className={cx(
      "col-start-13 col-end-14 transition-all ease-in-out duration-300    cursor-pointer touch-manipulation self-center",
      {
        "opacity-20": !enabled,
      }
    )}
    onClick={onClick}
    disabled={!enabled}
  >
    <Next className="m-auto" />
  </button>
);

const GalleryMobile = ({ imageGallery, onClose }) => {
  return (
    <div className="md:hidden bg-navy min-h-screen w-full absolute top-0 left-0 z-50 ">
      <Cross
        className="absolute top-4 right-6 cursor-pointer"
        onClick={() => onClose()}
      />
      <div className="flex flex-col mt-28 px-gutter space-y-16">
        {imageGallery?.map((img, index) => (
          <div>
            <Image {...img.image} key={index} className="min-w-full" />
            <p className="text-white mt-6">{img?.excerpt}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const Gallery = ({ imageGallery, onClose }) => {
  useScrollFreeze();

  const [currentIndex, setCurrentIndex] = useState(0);

  const [viewportRef, embla] = useEmblaCarousel({ skipSnaps: true });

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
    setCurrentIndex(embla.selectedScrollSnap());
  }, [embla]);

  const onSwipe = React.useCallback(() => {
    if (!embla) return;
    setCurrentIndex(embla.selectedScrollSnap());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    onSelect();

    embla.on("select", onSelect);
  }, [embla, onSelect]);

  React.useEffect(() => {
    if (!embla) return;
    onSwipe();
    embla.on("select", onSwipe);
  }, [embla, onSwipe]);

  return (
    <div className="hidden  bg-navy min-h-screen  w-full absolute top-0 left-0 z-50 md:grid grid-cols-14 grid-rows-2 pt-10 ">
      <Cross
        className="absolute top-4 right-6 cursor-pointer"
        onClick={() => onClose()}
      />
      <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />

      <div className="col-start-4 col-end-12 self-center">
        <div ref={viewportRef} className="w-full overflow-hidden">
          <div className="flex">
            {imageGallery?.map((img, i) => (
              <div
                className="aspect-w-16 aspect-h-10 relative w-full "
                style={{ flex: "0 0 auto" }}
              >
                <Image
                  className="absolute top-0 left-0 object-cover w-full h-full"
                  {...img.image}
                  key={i}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
      <AnimatePresence exitBeforeEnter>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          key={currentIndex}
          className="text-white flex col-start-4 col-end-12 row-start-2 row-end-3  "
        >
          <p className="w-3/4  text-lg">{`${currentIndex + 1}  of  ${
            imageGallery.length
          }`}</p>
          <p className="text-sm">{imageGallery[currentIndex]?.excerpt}</p>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

const Project = ({ data: { project } }) => {
  const { meta } = project || {};
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Helmet>
        <meta name="theme-color" content="#1E1B43"></meta>
      </Helmet>
      <Layout bg={meta?.pageColor?.value}>
        <Intro {...project} onOpen={() => setModalOpen(true)} />
        <Details {...project} onOpen={() => setModalOpen(true)} />
        <StandardModal isOpen={modalOpen}>
          {modalOpen && (
            <Gallery {...project} onClose={() => setModalOpen(false)} />
          )}
          {modalOpen && (
            <GalleryMobile {...project} onClose={() => setModalOpen(false)} />
          )}
        </StandardModal>
      </Layout>
    </>
  );
};

export default Project;

export const projectQuery = graphql`
  query standardProjectQuery($slug: String!) {
    project: sanityProject(slug: { current: { eq: $slug } }) {
      meta {
        ...MetaCardFields
        pageColor {
          title
          value
        }
        metaImage {
          ...ImageWithPreview
        }
      }
      content: _rawContent(resolveReferences: { maxDepth: 10 })
      excerpt
      caption
      featuredVideo
      imageGallery {
        excerpt
        image {
          ...ImageWithPreview
        }
      }
      sessions {
        date
        venue {
          title
          venueLink
        }
      }
      ticketsLink
      eventColor {
        picker {
          title
          value
        }
      }
      slug {
        current
      }
      heading
      image {
        ...ImageWithPreview
      }
    }
  }
`;
